import 'app/App.css';
import ImageHeaderComponent from './components/image_header/ImageHeaderComponent';
import PersonalDetailsComponent from './components/personal_details/PersonalDetailsComponent';
import EmploymentHistoryComponent from './components/employment_history/EmploymentHistoryComponent';
import EducationComponent from './components/education/EducationComponent';
import SkillsComponent from './components/skills/SkillsComponent';

function HomePage() {
  return (
    <div className='mt-6'>
      <section>
        <ImageHeaderComponent />
      </section>
      <section>
        <PersonalDetailsComponent />
      </section>
      <section id='employment-history' className='mt-24'>
        <EmploymentHistoryComponent></EmploymentHistoryComponent>
      </section>
      <section id='education' className='mt-24 page-break--always'>
        <EducationComponent />
      </section>
      <section id='skills' className='mt-24'>
        <SkillsComponent />
      </section>
    </div>
  );
}

export default HomePage;
