import React from 'react'
import me from 'assets/me.jpg';

type Props = {}

function ImageHeaderComponent(props: Props) {
  return (
    <div>
    <div className="flex justify-center">
      <img src={me} className="w-32 rounded-full" alt="me" />
    </div>
    <div className='flex flex-col items-center p-4'>
      <h1 className='text-xl font-bold'>Robert Pauw</h1>
      <h2 className='text-l text-subtitle'>Full stack developer</h2>
    </div>
  </div>
    )
}

export default ImageHeaderComponent