type Props = {}

function SkillsComponent(props: Props) {
  return (
    <div className='container mx-auto lg:max-w-3xl'>
      <div className='flex justify-center'>
        <h2 className='font-medium'>SKILLS</h2>
      </div>
      <hr className='border-blue mt-1'></hr>
      <div className='flex justify-center flex-wrap gap-4 mt-12'>
        <Skill name="Flutter" stars={5} />
        <Skill name="Vue.js" stars={5} />
        <Skill name="JavaScript" stars={5} />
        <Skill name="TypeScript" stars={5} />
        <Skill name="Laravel / lumen" stars={4} />
        <Skill name="PHP" stars={4} />
        <Skill name="React" stars={4} />
        <Skill name="MySql" stars={4} />
        <Skill name="SASS" stars={4} />
        <Skill name="Node.js" stars={4} />
        <Skill name="Cypress" stars={4} />
        <Skill name="Python" stars={3} />
        <Skill name="C#" stars={3} />
        <Skill name="Angular" stars={3} />
        <Skill name="Linux" stars={3} />
        <Skill name="Nginx" stars={3} />
        <Skill name="Git" stars={3} />
      </div>
      <div className='flex justify-center mt-24'>
        <h2 className='font-medium'>LANGUAGES</h2>
      </div>
      <hr className='border-blue mt-1'></hr>
      <div className='flex justify-center flex-wrap gap-4 mt-12'>
        <Skill name="Dutch" stars={5} />
        <Skill name="English" stars={3} />
      </div>
    </div>
  )
}

function Skill(props: { name: string, stars: number }) {
  return (
    <div className="flex flex-col items-center">
      <p className="text-subtitle">{props.name}</p>
      <div className="flex mt-1">
        <div className={`rounded-2xl w-2 h-2 me-1 ${props.stars > 0 ? 'bg-blue' : 'bg-light'}`}></div>
        <div className={`rounded-2xl w-2 h-2 me-1 ${props.stars > 1 ? 'bg-blue' : 'bg-light'}`}></div>
        <div className={`rounded-2xl w-2 h-2 me-1 ${props.stars > 2 ? 'bg-blue' : 'bg-light'}`}></div>
        <div className={`rounded-2xl w-2 h-2 me-1 ${props.stars > 3 ? 'bg-blue' : 'bg-light'}`}></div>
        <div className={`rounded-2xl w-2 h-2 ${props.stars > 4 ? 'bg-blue' : 'bg-light'}`}></div>
      </div>
    </div>
  )
}

export default SkillsComponent