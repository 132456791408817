type Props = {}

function EducationComponent(props: Props) {
    return (
        <div className='container mx-auto lg:max-w-3xl'>
            <div className='flex justify-center'>
                <h2 className='font-medium'>EDUCATION</h2>
            </div>
            <hr className='border-blue mt-1'></hr>
            <div className='flex justify-center flex-wrap mt-12'>
                <div className="w-full mb-8">
                    <Education title='Informatica' grade='Bachelor of Science' date='Sept. 2013 - Jul. 2018' location='University of The Hague' />
                </div>
                <div className="w-full">
                    <Education title='Marketing & Communication' grade='College degree' date='Sept. 2009 - Jul. 2012' location='IDCollege' />
                </div>
            </div>
        </div>
    )
}

function Education(params: { title: string, grade: string, date: string, location: string }) {
    return (
        <div className='flex items-center justify-between w-full px-6'>
            <div>
                <div className='flex items-center'>
                    <p className='font-medium'>{params.title}</p>
                </div>
                <p className='text-subtitle text-small'>{params.date}</p>
            </div>
            <div>
                <p className='text-subtitle text-small text-end'>{params.grade}</p>
                <p className='text-subtitle text-small text-end'>{params.location}</p>
            </div>
        </div>
    )
}

export default EducationComponent
