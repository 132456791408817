import React from 'react';
import './App.css';
import HomePage from 'pages/home/Home';

function App() {
  return (
    <div className="App text-content m-6 pb-12">
      <HomePage />
    </div>
  );
}

export default App;
