import { AtSymbolIcon, MapPinIcon, PhoneIcon } from '@heroicons/react/20/solid'

type Props = {}

function PersonalDetailsComponent(props: Props) {
  return (
      <div className='flex justify-center flex-wrap gap-6'>
        <Detail icon={<MapPinIcon height={24} />} subtitle={'The Netherlands'}></Detail>
        <Detail icon={<AtSymbolIcon height={24} />} subtitle={'pauwrobert@gmail.com'}></Detail>
      </div>
  );
}

function Detail(props: {icon: any, subtitle: String}) {
  return (
    <div className='flex'>     
      <span className='text-button me-1'>{props.icon}</span>
      <span className='text-subtitle'>{props.subtitle}</span>
    </div>
  );
}

export default PersonalDetailsComponent