import energiebalansplanner from 'assets/energiebalansplanner.png';
import or from 'assets/or.webp';
import boekie from 'assets/boekie.png';
import daya from 'assets/daya.webp';
import voetbalpoules from 'assets/voetbalpoules.webp';
import sleverbouw from 'assets/sleverbouw.jpeg';
import pro from 'assets/pro.jpeg';
import instawoosh from 'assets/instawoosh.webp';


type Props = {}

function EmploymentHistoryComponent(props: Props) {
    return (
        <div className='container mx-auto lg:max-w-3xl'>
            <div className='flex justify-center'>
                <h2 className='font-medium'>PROJECT HISTORY</h2>
            </div>
            <hr className='border-blue mt-1'></hr>
            <div className='mt-12'>
                <HistoryCompany title='Stateless' contract='Freelance' date='Feb. 2020 - Present' location='Leidschendam' />
            </div>
            <div className='flex justify-center flex-wrap'>
                <History title={'Voetbalpoules'} languages={['Flutter']} content={getFootballPoulsContent()} img={voetbalpoules} playStoreLink='https://play.google.com/store/apps/details?id=com.voetbalpoules.nativeapp&gl=US' appStoreLink='https://apps.apple.com/nl/app/voetbalpoules/id1371723794'></History>
                <div className='page-break--always'>
                    <History title={'Daya'} languages={['Vue.js', 'TypeScript', 'Cypress', 'PHP Lumen']} img={daya} content={getDayaContent()} link='https://daya.nl'></History>
                </div>
                <History title={'Energie balans planner'} languages={['Flutter', 'C# .NET']} content={getDailyActivityContent()} img={energiebalansplanner} playStoreLink='https://play.google.com/store/apps/details?id=com.stateless.dailyactivity' appStoreLink='https://apps.apple.com/us/app/energie-balans-planner/id1566354248'></History>
               
                <div className='page-break--always'>
                    <History title={'Boekie'} languages={['Flutter', 'Laravel']} content={getBoekieContent()} img={boekie} ></History>
                </div>

                <History title={'PRO Automotive'} languages={['Vue.js', 'TypeScript', 'C# .NET']} content={getProAutomotiveContent()} img={pro} link='https://proautomotive.nl'></History>
                <History title={'Opbouw van Reserves'} languages={['Flutter']} content={getOrContent()} img={or} playStoreLink='https://play.google.com/store/apps/details?id=com.stateless.or' appStoreLink='https://apps.apple.com/nl/app/opbouw-van-reserves/id6446911265'></History>
                
                <div className='page-break--always'>
                    <History title={'SleVerBouw'} languages={['Vue.js', 'TypeScript', 'Python']} content={getSleverbouwContent()} img={sleverbouw} link='https://sleverbouw.stateless.nl' ></History>
                </div>
                
            </div>
            <div className='mt-10'>
                <HistoryCompany title='Flaire' contract='Employee' date='Okt. 2018 - Sept. 2020' location='Zoetermeer' />
            </div>
            <div className='flex justify-center flex-wrap'>
                <History title={'Flaire'} languages={['Vue.js', 'Laravel']} content={getFlaireContent()} link='https://flaire.nl'></History>
            </div>
            <div className='mt-10'>
                <HistoryCompany title='DuoDev' contract='Freelancer' date='2017 - 2018' location='Remote' />
            </div>
            <div className='flex justify-center flex-wrap'>
                <History title={'InstaWoosh'} languages={['Angular', 'Laravel', 'Puppeteer', 'Selenium']} content={getInstaWooshContent()} img={instawoosh} ></History>
            </div>
        </div>
    )
}

function getBoekieContent(): string {
    return 'Boekie is a mobile app specifically designed for independent contractors, freelancers, and gig workers, aiming to make self-accounting a breeze. With Boekie, you can effortlessly track your invoices and purchases, and with just a single button press, you can file your value-added tax (VAT) and income tax returns.'
}
function getSleverbouwContent(): string {
    return 'Sleverbouw specializes in property maintenance and installation technology. For Sleverbouw, I\'ve developed a web application that facilitates project creation and management. This makes it easy to understand the status of a project, what tasks are completed and which materials were used. When an inspection is conducted at the client\'s location, a checklist is executed through the web application, ensuring nothing is overlooked or left behind.'
}
function getOrContent(): string {
    return 'A tool designed to conduct daily OR measurements following the VAS scale. Monitor or share your weekly average to aid in reestablishing your energy balance.'
}
function getDailyActivityContent(): string {
    return 'In the aftermath of the COVID-19 pandemic, many individuals have been grappling with the effects of \'long COVID\', a significant symptom of which is a notable decrease in energy levels and difficulty in maintaining their balance. In response to this, I collaborated with multiple occupational therapists to create the \'Energy Balance Planner\' app. </br></br>' +
    'This app enables users to plan their daily activities and assign a particular importance or \'weight\' to each. Using sophisticated algorithms, the app provides users with insight into whether they can accommodate additional activities or if their schedule is already overloaded. The app also offers a unique feature that allows users to connect with their occupational therapist, facilitating continuous tracking of user progress and enabling therapists to provide guidance or notes when necessary.</br></br>' +
    'As of now, the app boasts over 400 daily active users and continues to grow as a tool for managing the ongoing effects of long COVID'
}
function getFootballPoulsContent(): string {
    return 'In the Netherlands, football tournaments are more than just a sporting event - they are opportunities for friends and family to engage in friendly competition, predicting match outcomes and participating in pools. This is precisely the premise of Voetbalpoules, a popular platform that provides such an experience. The owner enlisted my services to develop a mobile application that complements their existing website and backend.<br><br>' +
    'Given the budget constraints that necessitated a unified codebase for both Android and iOS, I opted to utilize Flutter for the project. This marked my inaugural venture into Flutter development, and I learned a considerable amount from the experience. I predominantly relied on standard libraries and architecture, while incorporating the BLoC pattern for state management. <br><br>' +
    'Today, the app is thriving with an impressive daily user count exceeding 300,000, testifying to its widespread acceptance among the football-loving populace.'
}
function getDayaContent(): string {
    return 'In an effort to streamline the administrative duties of entrepreneurs and advisors, we have initiated the development of a comprehensive solution to manage relationships, invoices, and other financial administrative tasks. This product, currently in its early closed testing stage, promises to revolutionize business administration once fully released. The backend has been constructed using Laravel Lumen and Python, with the integration of microservices to enhance the project\'s maintainability and scalability. We employ TeamCity for release pipelines and utilize self-hosted Sentry for application monitoring.<br><br>' +
    'On the frontend, I utilized Vue.js and TypeScript in conjunction with SCSS for markup style. This marked my first extensive project using Vue.js. One of the main challenges I encountered was structuring the directories and files due to the large scale of the project. To ensure code readability and maintain a clean structure, I adopted a component-based approach where each component is responsible for its own code and style. This modular design has facilitated the reuse of components across the application, improving development efficiency and application consistency.'
}
function getProAutomotiveContent(): string {
    return 'PRO Automotive, based in the Netherlands, facilitates the import and export of cars for businesses across various countries. To streamline their internal workflow, specifically pertaining to the export side of the business, we developed a web application. This application interfaces with several external APIs, such as the RDW, to collate vehicle information and implement updates when required. The web app is designed to accommodate multiple user roles, including Administrators, Employees, and Clients.<br><br>' +
    'The frontend of the application was constructed using Vue.js in conjunction with TypeScript, creating a robust and user-friendly interface to manage the complexities of international automotive trade.'
}
function getFlaireContent(): string {
    return 'Flaire, a design and development company, specializes in creating applications and websites for clients on a project basis. This was the first company I joined after graduating, marking my initial foray into the professional realm. Here, I learned foundational programming skills, honed my ability to manage multiple projects simultaneously, and gained valuable experience in team collaboration.'
}
function getInstaWooshContent(): string {
    return 'An automated application to boost the likes, followers and activity on Instragram accounts. I\'ve created the frontend in Angular, the backend in Laravel and the automated process in PHP Puppeteer.'
}

function HistoryCompany(params: { title: string, contract: string, date: string, location: string }) {
    return (
        <div className='flex items-center justify-between w-full bg-blue rounded-2xl p-6 '>
            <div>
                <div className='flex items-center flex-wrap'>
                    <p className='font-medium text-white me-1'>{params.title}</p>
                    <p className='text-subtitle text-small text-white'>- {params.contract}</p>
                </div>
                <p className='text-subtitle text-small text-white'>{params.date}</p>
            </div>
            <div>
                <p className='text-subtitle text-small text-end text-white'>Full stack developer</p>
                <p className='text-subtitle text-small text-end text-white'>{params.location}</p>
            </div>
        </div>
    )
}

function History(params: { title: string, languages: Array<string>, content: string, img?: any, link?: string, appStoreLink?: string, playStoreLink?: string }) {
    return (
        // drop-shadow-lg
        <div className='bg-history rounded-2xl p-6 relative drop-shadow-lg mt-10'>
            {
                params.img != null &&

                <div className="absolute left-0 top-0 -ml-5 -mt-5">
                    <img className="w-10 h-10 rounded-xl" src={params.img} alt="" />
                </div>
            }
            <div className='flex justify-between items-center'>
                <h3 className='font-medium'>{params.title}</h3>
                <h3 className='text-subtitle text-small pl-8'>{params.languages.join(', ')}</h3>
            </div>
            <p className='pt-4' dangerouslySetInnerHTML={{ __html: params.content }}></p>
            <div className='pt-4'>
                {
                    params.link != null &&
                    <a href={params.link} target='_blank' rel='noreferrer' className='text-button me-4'>Go to website</a>
                }
                {
                    params.appStoreLink != null &&
                    <a href={params.appStoreLink} target='_blank' rel='noreferrer' className='text-button me-4'>AppStore</a>
                }
                {
                    params.playStoreLink != null &&
                    <a href={params.playStoreLink} target='_blank' rel='noreferrer' className='text-button'>PlayStore</a>
                }
            </div>
        </div>
    )
}

export default EmploymentHistoryComponent
